import Events from '@/helpers/events';

export default new class Router {
    constructor() {
        const events = new Events();
        events.bind(this);

        this.options = {
            pagecontainer: '.page-container',
            defaultTransition: 'fade',
            ajaxloading: true,
        };

        // variable for control key is pressed
        this.CTRL_PRESSED = false;

        this.modules = [];
        this.runningModules = [];

        this.loading = false;

        this.popped = ('state' in window.history && window.history.state !== null);
        this.initialURL = window.location.pathname;
        this.currentPathname = this.initialURL;
        this.ajaxRequest = null;
    }

    setup(data) {
        Object.assign(this.options, data.options);
        this.addModules(data.modules);
    }

    addModules(modules) {
        this.modules = [this.modules, ...modules];

        this.fireModules();
    }

    fireModules() {
        this.off('afterLoad');

        this.destroyModules();

        this.modules.forEach((module) => {
            if (module.element) {
                const $element = document.querySelectorAll(module.element);

                if ($element.length > 0) {
                    $element.forEach((el) => {
                        this.fireModule(el, module);
                    });
                }
            } else {
                this.fireModule(document, module);
            }
        });
    }

    fireModule(element, module) {
        if (typeof module.class === 'function') {
            const ModuleClass = module.class;
            const createdModule = new ModuleClass(element, module.options);

            this.runningModules.push(createdModule);
        } else if (typeof module.load === 'function') {
            (async () => {
                const LoadedModule = await module.load();
                const ModuleClass = LoadedModule.default;
                const createdModule = new ModuleClass(element, module.options);
                this.runningModules.push(createdModule);
            })();
        }
    }

    destroyModules() {
        this.trigger('destroy');

        this.runningModules.forEach((module) => {
            if (typeof module.removeEventListeners === 'function' && module.$el) {
                module.removeEventListeners();
            }
        });

        this.runningModules = [];
    }

    addEventListeners() {

    }

    updateModule(moduleName) {
        this.modules.forEach((module) => {
            if (moduleName === module.name) {
                const ModuleClass = module.class;
                const runningModule = new ModuleClass(module.element, module.options);

                this.runningModules.push(runningModule);
            }
        });
    }
}();
