// import bugsnag from '@bugsnag/js';
import '../../sass/index.scss';
import Router from '@/core/router';
import Parallax from '@/modules/parallax';
// import Cookie from '@/modules/cookie';

// if (process.env.bugsnag) {
//     window.bugsnagClient = bugsnag('dd8171456cbe005f24c90418f2fc9f1d');
// }

Router.setup({
    options: {
        pagecontainer: '.page-container',
        defaultTransition: 'fade',
        ajaxloading: false,
    },
    modules: [
        {
            load: () => import(/* webpackChunkName: "order" */'@/modules/order'),
            element: '#order-product',
        },
        {
            class: Parallax,
        },
        // {
        //     load: () => import(/* webpackChunkName: "terugbellen" */'@/modules/terugbellen'),
        //     element: '.terugbellen',
        // },
        // {
        //     class: Cookie,
        // },
    ],
});
