import { TweenMax, Linear } from 'gsap';
import { MorphSVGPlugin } from '@/vendor/tweenmax/MorphSVGPlugin'; // eslint-disable-line import/no-named-as-default
import Core from '@/core/module';

export default class Parallax extends Core {
    init() {
        this.depth = 0.4;
        this.maxMovement = 40;

        this.$el.querySelectorAll('[depth]').forEach(($el) => {
            const depth = $el.getAttribute('depth');

            const bezierData = MorphSVGPlugin.pathDataToBezier(`
                M7.1,0.5c-3.6,0-6.6,2.4-6.6,5.4s2.9,5.4,6.6,5.4c6.5,0,14.3-10.7,20.9-10.7c3.6,0,6.6,2.4,
                6.6,5.4s-2.9,5.4-6.6,5.4C21.3,11.2,13.6,0.5,7.1,0.5z
            `);

            bezierData.forEach((item) => {
                item.x *= depth;
                item.y *= depth;
            });

            TweenMax.to($el, 10, { bezier: { values: bezierData, type: 'cubic' }, ease: Linear.easeNone, repeat: -1 });
        });
    }
}
